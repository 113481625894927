import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import VehicleSalesChart from './VehicleSalesChart';

// Fetch vehicles from the API
const fetchVehicles = async (series, badge, transmission, setVehicles) => {
  let query = '';

  if (series.length > 0) {
    query += `series=${series.join(',')}&`;
  }

  if (badge.length > 0) {
    query += `badge=${badge.join(',')}&`;
  }

  if (transmission.length > 0) {
    query += `transmission=${transmission.join(',')}&`;
  }

  // Default to fetching all vehicles if no filters are applied
  
  const baseUrl = process.env.REACT_APP_API_URL 

  const url = query ? `${baseUrl}?${query}` : baseUrl; //PROD
  
  try {
    const response = await fetch(url);
    const data = await response.json();
    setVehicles(data);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

function VehicleSalesDashboard({ selectedSeries, selectedBadge, selectedTransmission }) {
  const [vehicles, setVehicles] = useState([]);

  // Use the effect to call the fetchVehicles function when the filters change
  useEffect(() => {
    console.log('Fetching data with filters:', { selectedSeries, selectedBadge, selectedTransmission });
    fetchVehicles(selectedSeries, selectedBadge, selectedTransmission, setVehicles);
  }, [selectedSeries, selectedBadge, selectedTransmission]);

  return (
    <div className="app-background">
      <Header />
      <div className="container mx-auto px-4 py-4">
        <h2 className="text-2xl font-bold mb-4">Sales Overview</h2>
        
        {/* Pass filtered vehicles to the chart */}
        <VehicleSalesChart data={vehicles} />
      </div>
      <Footer />
    </div>
  );
}

export default VehicleSalesDashboard;
