import React, { useState } from 'react';
import './App.css';
import './index.css';
import VehicleSalesDashboard from './components/VehicleSalesDashboard';
import FilterGroup from './components/FilterGroup';  // Import the reusable component
import GroupedBadgeSelect from './components/GroupedBadgeSelect';
import { Helmet } from 'react-helmet';

function App() {
  const [showChart, setShowChart] = useState(false);
  const [selectedSeries, setSelectedSeries] = useState([]); // State for selected series
  const [selectedBadge, setSelectedBadge] = useState([]); // State for selected badges
  const [selectedTransmission, setSelectedTransmission] = useState([]); // State for selected transmission
  const [clear, setClear] = useState(false);

  // Series, Badge, Transmission options
  const seriesOptions = ['997', '991', '992'];
  const transmissionOptions = ['Automatic', 'Manual'];

  // Function to show the chart without scrolling
  const toggleChartVisibility = () => {
    setShowChart(true);
  };

  // Toggle functions for series, badge, transmission
  const toggleOption = (selected, setSelected) => (option) => {
    setSelected((prev) =>
      prev.includes(option) ? prev.filter((s) => s !== option) : [...prev, option]
    );
  };

  // Function to clear all selections
  const clearFilters = () => {
    setSelectedSeries([]);
    setSelectedBadge([]);
    setSelectedTransmission([]);
    setClear(true);
    setTimeout(() => setClear(false), 100); // Reset clear state after a short delay
  };
  return (
    <div className="app-container">
      <Helmet>
        <title>Porsche Sales Tracker - {selectedSeries.join(', ')} {selectedBadge.join(', ')} {selectedTransmission.join(', ')}</title>
        <meta name="description" content={`Analyze Porsche sales trends for ${selectedSeries.join(', ')} ${selectedBadge.join(', ')} ${selectedTransmission.join(', ')}`} />
      </Helmet>
      {/* Hero Section */}
      <section className="hero-section small-hero">
        <div>
          <p>Your ultimate source for tracking Porsche sales trends</p>
          <button className="cta-button" onClick={toggleChartVisibility}>Explore Now</button>
        </div>
      </section>

      {/* Chart Section with smooth fade-in animation */}
      {showChart && (
        <div id="chart-section" className="min-h-screen bg-gray-100 flex flex-col fade-in">
          {/* Filtering buttons */}
          <div className="filter-container flex flex-col items-center">
            <FilterGroup
              title="Select Series"
              options={seriesOptions}
              selectedOptions={selectedSeries}
              toggleOption={toggleOption(selectedSeries, setSelectedSeries)}
            />
            <GroupedBadgeSelect
              selectedBadges={selectedBadge}
              setSelectedBadges={setSelectedBadge}
              clear={clear}
            />
            <FilterGroup
              title="Select Transmission"
              options={transmissionOptions}
              selectedOptions={selectedTransmission}
              toggleOption={toggleOption(selectedTransmission, setSelectedTransmission)}
            />
            <button className="clear-button" onClick={clearFilters}>Clear Filters</button>
          </div>

          {/* Main Dashboard - Pass selected filters as props */}
          <VehicleSalesDashboard
            selectedSeries={selectedSeries}
            selectedBadge={selectedBadge}
            selectedTransmission={selectedTransmission}
          />
        </div>
      )}
    </div>
  );
}

export default App;
