import React from 'react';

const FilterGroup = ({ title, options, selectedOptions, toggleOption }) => {
  return (
    <div className="filter-group">
      <h2>{title}</h2>
      <div className="filter-options">
        {options.map((option) => (
          <button
            key={option}
            onClick={() => toggleOption(option)}
            className={`filter-button ${selectedOptions.includes(option) ? 'selected' : ''}`}
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
};

export default FilterGroup;
