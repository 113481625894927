// components/Header.js
import React from 'react';

function Header() {
  return (
    <header className="bg-black w-full text-white text-center py-4">
    <h1 className="text-3xl font-bold uppercase tracking-wider">
     
    </h1>
  </header>

  );
}

export default Header;
