// components/Footer.js
import React from 'react';

function Footer() {
  return (
    <footer className="bg-black w-full text-white text-center py-2">
    <p>© 2024 Vehicle Sales Dashboard</p>
    </footer>

  );
}

export default Footer;
