import React, { useState, useEffect } from 'react';

const GroupedBadgeSelect = ({ selectedBadges, setSelectedBadges, clear }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const badgeOptions = [
    "50th Anniversary Edition", "Black Edition", "Carrera 4", "Carrera 4 Cabriolet",
    "Carrera 4 GTS", "Carrera 4 GTS Coupe", "Carrera 4S", "Carrera 4S Cabriolet",
    "Carrera 4S Coupe", "Carrera Cabriolet", "Carrera Coupe", "Carrera GTS",
    "Carrera GTS Cabriolet", "Carrera S", "Carrera S Cabriolet", "Carrera S Coupe",
    "Carrera T", "Coupe", "Dakar", "GT2", "GT2 RS", "GT2 RS Weissach", "GT3",
    "GT3 RS", "GT3 RS 4.0", "GT3 RS Weissach", "GT3 Touring", "R", "Speedster",
    "Sport Classic", "Targa 4", "Targa 4 GTS", "Targa 4S",
    "Targa Edition 50 Years Porsche Design", "Turbo", "Turbo Cabriolet",
    "Turbo Coupe", "Turbo S", "Turbo S Cabriolet",
    "Turbo S Cabriolet Exclusive Series", "Turbo S Coupe"
  ].sort();

  const badgeCategories = {
    Carrera: badgeOptions.filter(badge =>  
      badge.toLowerCase().includes('carrera') || 
      badge.toLowerCase().includes('50th Anniversary Edition')
    ),
    Turbo: badgeOptions.filter(badge => badge.toLowerCase().includes('turbo')),
    GT: badgeOptions.filter(badge =>  
      badge.toLowerCase().includes('gt3') || 
      badge.toLowerCase().includes('gt2')
    ),
    Targa: badgeOptions.filter(badge => badge.toLowerCase().includes('targa')),
    'Special Badge': badgeOptions.filter(badge => 
      !['carrera', 'turbo', 'gt', 'targa'].some(cat => badge.toLowerCase().includes(cat))
    )
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category === selectedCategory ? null : category);
  };

  const handleBadgeToggle = (badge) => {
    setSelectedBadges(prev => 
      prev.includes(badge) ? prev.filter(b => b !== badge) : [...prev, badge]
    );
  };

  useEffect(() => {
    if (clear) {
      setSelectedCategory(null);
      setSelectedBadges([]);
    }
  }, [clear, setSelectedBadges]);

  return (
    <div className="grouped-badge-select">
      <h2>Select Badge</h2>
      <div className="badge-categories">
        {Object.keys(badgeCategories).map(category => (
          <div key={category} className="badge-category">
            <button
              onClick={() => handleCategoryClick(category)}
              className={`category-button ${selectedCategory === category ? 'selected' : ''}`}
            >
              {category}
            </button>
            {selectedCategory === category && (
              <div className="badge-options">
                {badgeCategories[category].map(badge => (
                  <button
                    key={badge}
                    onClick={() => handleBadgeToggle(badge)}
                    className={`badge-button ${selectedBadges.includes(badge) ? 'selected' : ''}`}
                  >
                    {badge}
                  </button>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GroupedBadgeSelect;