import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { regressionLinear } from 'd3-regression';

const VehicleSalesChart = ({ data = [], selectedSeries = [], selectedTransmission = [], selectedBadges = [] }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (data.length > 0) {
      const chartContainer = chartRef.current;
      const containerWidth = chartContainer.clientWidth;
      const margin = { top: 20, right: 40, bottom: 70, left: 100 };
      const width = containerWidth - margin.left - margin.right;
      const height = Math.min(500, window.innerHeight * 0.6) - margin.top - margin.bottom;

      // Clear existing chart
      d3.select(chartContainer).selectAll("*").remove();

      // Create responsive SVG
      const svg = d3.select(chartContainer)
        .append("svg")
        .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
        .attr("preserveAspectRatio", "xMidYMid meet")
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // Filter data based on selected series, transmission, and badges
      const filteredData = data.filter((d) =>
        (selectedSeries.length === 0 || selectedSeries.includes(d.series)) &&
        (selectedTransmission.length === 0 || selectedTransmission.includes(d.transmission)) &&
        (selectedBadges.length === 0 || selectedBadges.includes(d.badge))
      );

      const minPrice = d3.min(filteredData, d => +d.sale_price);
      const maxPrice = d3.max(filteredData, d => +d.sale_price);

      // Set up scales
      const x = d3.scaleTime()
        .domain([
          d3.timeMonth.offset(d3.min(filteredData, d => new Date(d.sale_date)), -3),
          d3.timeMonth.offset(d3.max(filteredData, d => new Date(d.sale_date)), 3)
        ])
        .range([0, width]);

      const y = d3.scaleLinear()
        .domain([minPrice - 10000, maxPrice + 10000])
        .range([height, 0]);

      // Define color scale based on series
      const colorScale = d3.scaleOrdinal()
        .domain(['997', '991', '992'])
        .range(['#1f77b4', '#ff7f0e', '#2ca02c']);

      // Define shapes for different badges
      const badgeShapeMap = {
        'Carrera 4S': d3.symbolCircle,
        'Carrera GTS': d3.symbolSquare,
        'Targa 4S': d3.symbolTriangle,
        'Carrera S': d3.symbolDiamond,
        'GT3': d3.symbolCross,
        'GT2 RS': d3.symbolStar
      };

      const symbolGenerator = d3.symbol().size(100);

      // Add X axis
      svg.append('g')
        .attr('transform', `translate(0,${height})`)
        .call(d3.axisBottom(x).tickFormat(d3.timeFormat('%b %Y')))
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-45)");

      // Add Y axis
      svg.append('g')
        .call(d3.axisLeft(y).tickFormat(d => `$${d3.format(",.0f")(d)}`));

      // Add X axis label
      svg.append("text")
        .attr("x", width / 2)
        .attr("y", height + margin.bottom - 10)
        .style("text-anchor", "middle")
        .style("font-size", "1.2rem")
        .style("fill", "#333")
        .text("Date of Sale");

      // Add Y axis label
      svg.append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 0 - margin.left + 40)
        .attr("x", 0 - (height / 2))
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .style("font-size", "1.2rem")
        .style("fill", "#333")
        .text("Sale Price (USD)");

      // Add trendline (regression line)
      const regression = regressionLinear()
        .x(d => new Date(d.sale_date))
        .y(d => +d.sale_price);

      const trendData = regression(filteredData);

      svg.append("path")
        .datum(trendData)
        .attr("d", d3.line()
          .x(d => x(new Date(d[0])))
          .y(d => y(d[1]))
        )
        .style("stroke", "red")
        .style("stroke-width", 2)
        .style("fill", "none");

      // Create tooltip
      const tooltip = d3.select("body").append("div")
        .attr("class", "tooltip")
        .style("position", "absolute")
        .style("background", "#333")
        .style("color", "#fff")
        .style("padding", "8px")
        .style("border-radius", "4px")
        .style("pointer-events", "none")
        .style("opacity", 0);

      // Add shapes for data points based on badge with tooltip
      svg.append('g')
        .selectAll('path')
        .data(filteredData)
        .enter().append('path')
        .attr('d', d => {
          const shapeType = badgeShapeMap[d.badge] || d3.symbolCircle; // Default to circle if badge not found
          return symbolGenerator.type(shapeType)();
        })
        .attr('transform', d => `translate(${x(new Date(d.sale_date))},${y(d.sale_price)})`)
        .attr('fill', d => colorScale(d.series))
        .attr('opacity', 0.8)
        .on("mouseover", function(event, d) {
          tooltip.transition().duration(200).style("opacity", 0.9);
          tooltip.html(`
            <strong>Series:</strong> ${d.series}<br/>
            <strong>Badge:</strong> ${d.badge}<br/>
            <strong>Transmission:</strong> ${d.transmission}<br/>
            <strong>Sale Price:</strong> $${d.sale_price.toLocaleString()}<br/>
            <strong>Sale Date:</strong> ${new Date(d.sale_date).toLocaleDateString()}<br/>
            <strong>Mileage:</strong> ${d.mileage.toLocaleString()} miles<br/>
            <strong>Color:</strong> ${d.color}<br/>
          `)
          .style("left", (event.pageX + 15) + "px")
          .style("top", (event.pageY - 28) + "px");
        })
        .on("mouseout", function() {
          tooltip.transition().duration(500).style("opacity", 0);
        });

    }
  }, [data, selectedSeries, selectedTransmission, selectedBadges]);

  return <div ref={chartRef} className="chart-container"></div>;
};

export default VehicleSalesChart;
